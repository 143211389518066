











import { Vue, Component } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import { AdminRoutes } from '@/shared/router/admin'
import FormBase from '@/shared/classes/form/form-base'
import AuthLayout from '@/admin/layouts/AuthLayout.vue'
import { Positions } from '@/shared/interfaces/classes/form.interfaces'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Form from '@/shared/components/form/Form.vue'

@Component({
  components: {
    AuthLayout,
    Form
  },
  methods: {
    __
  }
})
export default class RemindPassword extends Vue {
  message: string | null = null
  form: FormBase|null = null

  created() {
    this.form = new FormBase()
      .setEndpoint('password/send')
      .setSubmit({
        text: __('admin.views.remind-password.form.submit'),
        position: Positions.center
      })
      .setFields([
        new Field()
          .setType(FieldTypes.email)
          .setKey('email')
          .setTitle(__('admin.views.remind-password.form.email'))
      ])
      .setInjectValues({
        url: this.$router.resolve({
          name: AdminRoutes.resetPassword,
          params: {
            token: ':token',
            email: ':email'
          }
        }).href
      })
      .setOnSuccess(this.onSuccess)
  }

  onSuccess(response: any) {
    this.message = response.message
  }

  goToLogin(): void {
    this.$router.push({ name: AdminRoutes.login })
  }
}
